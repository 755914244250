//module

.spdisp{display: none;}

a{text-decoration: none;color: $BLUE;transition:all 0.2s $ease5;
	&:hover{text-decoration: underline;}
}
// clearfix
.cf:after{content:"";display:block;	clear:both;}

.center {	text-align: center;}
.right {	text-align: right;}
.left {	text-align: left;}
.fl-left {	float: left;}
.fl-right {	float: right;}
.cl{clear:both;}


.btn{
	
}

.btn-area{
	display: flex;justify-content: center;margin-top: 40px;
}

.arrow{

}

//フォントサイズ
.l{font-size:1.8rem;display: inline-block;}
.s{font-size:1.4rem;display: inline-block;}
.xs{font-size:1.2rem;display: inline-block;}

//フォントカラー
.red{	color: $RED;}

strong{color: $BLACK;font-weight: 700;}

@include for-sp(){
	.spdisp{display: block;}
	.sphide{display: none;}
	.xs{font-size: 1.0rem;}
	.s{font-size: 1.2rem;}
	.l{font-size: 1.6rem;}
}


//表組み
table{
	width: 100%;font-size: $FONT_SIZE;line-height: 1.5;
	border-top: 1px solid $KEYCOLOR;border-bottom: 1px solid $KEYCOLOR;
	th,td{padding:16px 10px;border: none;}
	tr{
		border-bottom: 1px solid $BDC;
		&:last-child{border: none;}
	}
	thead th{	font-weight: 500; text-align: center;}
	tbody th{ width: 200px;font-weight: 500;background: $BGC_GRAY;color: $BLACK;}
}
figcaption{
	font-size: 1.2rem;color: $GRAY;
}
@include for-sp(){
	table{
		&:not(.nobreak){
			tr,th,td{display: block;border: none;padding: 0;width: 100%;}
			tr{border-top: 1px solid $BDC;
				&:first-child{border: none;}
			}
			th,td{padding: 5px;}
		}
	}
}


//広告
.ad-side{
	width: 300px;margin-top: 60px;
	&:first-child{
		margin-top: 0;
	}
}
@include for-sp(){
	.ad-side{
		margin: 20px auto;
	}
}
.ad-main{
	width: 728px;margin-top: 60px;
	&:first-child{
		margin-top: 0;
	}
}
@include for-sp(){
	.ad-main{
		width: 100%;margin-top: 40px;
		a{
			img{@include max(w);}
		}
	}
}

.ad-entry{
	display: flex;justify-content: space-between;
	> div{
		width: 300px;
	}
}
@include for-sp(){
	display: block;
	> div{
		margin: 20px auto 0;
	}
}

.box{
	border: 1px solid $BDC;padding:1.5em;
	@include last-mb0();
	> * + *{margin-top:1em;}
}

.block{
	padding: 2em 0;
	&:first-child{padding-top: 0;}
	> * + *{margin-top:1.5em;}
}


//パンくずリスト
.breadcrumb{font-size:1rem;
	li{display: inline-block;
		&:before{content:"›";display: inline-block;margin: 0 5px 0 2px;color: $BDC;}
		&:first-child:before{display: none;}
	}
}

@include for-sp(){
	.breadcrumb{
		margin-top: 20px;
		li{
			&:last-child{
				display: block;margin-top: 5px;
				&:before{display: none;}
			}
		}
	}
}

//罫線
hr{	border: none;border-bottom: 1px dashed $BDC;padding-top:40px;margin-bottom: 40px;}

@include for-sp(){
	hr{padding-top: 20px;margin-bottom: 20px;}
}

//記事一覧
.archive-tit{
	font-weight: 500;font-size: 3.4rem;line-height: 1.4;color: $BLACK;margin-top: 40px;
}
@include for-sp(){
	.archive-tit{
		padding: 0 25px;font-size: 2.4rem;margin-top: 20px;
	}
}
.entry-list{
	display: grid;margin-top: 40px;
	grid-template-columns: 1fr 1fr;
	grid-gap: 50px;
}

@media (max-width:1120px){
	.entry-list{
		width: 680px;margin: 40px auto 0;
		grid-gap: 30px;
	}
}

@include for-sp(){
	.entry-list{
		width: 100%;margin: 20px auto 0;
		grid-gap: 10px;
	}
}

.entry-list-item{
	a{
		display: block;height: 100%;
		background: #FFF;color: inherit;
		box-shadow: rgba(0,0,0,0.15) 0 3px 10px 0;
		position: relative;padding-bottom: 50px;
	}
}

.entry-list-thumb{
	width: 100%;height: 0;padding-bottom: 56.25%;
	position: relative;background: $GRAY;
	img{
		width: 100%;height: 100%;object-fit:cover;
		position: absolute;left:0;top:0;
	}
}
.entry-list-inner{
	padding: 25px 20px calc(1.3rem * 1.5 * 3 + 10px);
	position: relative;
}
@include for-sp(){
	.entry-list-inner{
		padding: 10px 15px;
	}
}

.entry-list-tit{
	font-size: 1.7rem;line-height: 1.4;font-weight: 700;color: $BLACK;
}

@include for-sp(){
	.entry-list-tit{
		font-size: 1.4rem;
	}
}

.entry-list-summary{
	width: 100%;font-size: 1.3rem;line-height: 1.5;color: $GRAY;height: calc(1em * 1.5 * 3);
	position: absolute;bottom:0;left:0;padding: 0 20px;
}

@include for-sp(){
	.entry-list-summary{
		display: none;
	}
}

.entry-list-bottom{
	width: 100%;padding: 0 20px 12px;
	display: flex;justify-content: space-between;
	position: absolute;bottom:0;left:0;
}
@include for-sp(){
	.entry-list-bottom{
		padding: 0 15px 8px;
		flex-direction: column;
	}
}

.entry-list-category{

}
.entry-cat{
	display: inline-block;padding: 6px 4px;line-height: 1;font-size: 1.1rem;
	background: $KEYCOLOR;color: #fff;letter-spacing: 0.02em;font-weight: 500;
}
@include for-sp(){
	.entry-cat{
		font-size: 1rem;padding: 3px;
	}
}
.entry-date{
	font-size: 1.1rem;color: $GRAY;letter-spacing: 0.02em;
}
@include for-sp(){
	.entry-date{
		font-size: 1rem;
	}
}
//記事詳細

.entry-body{
	background: #fff;margin-top: 10px;
}
.entry-body-header{
	padding: 20px 40px;
}

@include for-sp(){
	.entry-body-header{
		padding: 10px 25px 20px;
	}
}

.entry-body-date{
	font-size: 1.4rem;color: $GRAY;letter-spacing: 0.02em;
}
@include for-sp(){
	.entry-body-date{
		font-size: 1.3rem;
	}
}
.entry-body-category{
	margin-top: 15px;
}
@include for-sp(){
	.entry-body-category{
		margin-top: 10px;
	}
}
.entry-body-tit{
	font-size: 3rem;line-height: 1.4;font-weight: 500;color: $BLACK;margin-top: 15px;
}
@include for-sp(){
	.entry-body-tit{
		font-size: 2.1rem;margin-top: 10px;
	}
}
.entry-body-image{
	width: 100%;height: 0;padding-bottom: 56.75%;
	position: relative;
	background: $GRAY;
	img{
		width: 100%;height: 100%;object-fit:cover;
		position: absolute;left:0;top:0;
	}
}
.entry-body-contents{
	padding: 40px;line-height: 2;
	> * + * {margin-top: 1.5em;}
	h2,h3,h4,h5,h6{
		font-weight: 500;color: $BLACK;line-height: 1.4;
	}
	h2{
		font-size: 2.8rem;border-bottom: 1px solid $KEYCOLOR;color: $KEYCOLOR;margin-top: 3em;
	}
	h3{
		font-size: 2.2rem;margin-top: 2.5em;
	}
	h4{
		font-size: 1.9rem;
	}
	h5{
		font-size: 1.7rem;
	}
	h6{
		font-size: 1.6rem;
	}
	ul:not([class]){
		li{padding-left: 15px;position: relative;margin-top: 0.5em;
			&:before{content:"";width: 6px;height: 6px;display: block;background: $KEYCOLOR;border-radius: 3px;
				position: absolute;left:2px;top:calc(0.9em - 3px);
			}
		}
	}
	ol:not([class]){
		li{margin-top: 5px;}
	}
	blockquote{
		background: $BGC_GRAY;padding: 4em 2em 2em;font-size: 1.5rem;line-height: 1.8;color: $GRAY;
		position: relative;
		>*{position: relative;z-index: 2;}
		> * + * {margin-top: 1em;}
		&:before{
			content:"“";font-family: "游ゴシック";font-size: 40rem;color: #E9E9E9;line-height: 1;
			position: absolute;z-index: 1;left:-10px;top:-10px;
		}
	}
	img{
		max-width: 648px;height: auto;
	}
	.entry-conclusion{
		padding: 1em 2em;background: $BGC_GREEN;
		>* + *{
			margin-top: 1em;
		}
	}
}
@include for-sp(){
	.entry-body-contents{
		padding: 25px;
		h2{
			font-size: 1.9rem;
		}
		h3{
			font-size: 1.6rem;
		}
		h4{
			font-size: 1.5rem;
		}
		h5{
			font-size: 1.4rem;
		}
		h6{
			font-size: 1.4rem;
		}
		blockquote{
			font-size: 1.3rem;
			&:before{
				font-size: 20rem;
			}
		}
		img{
			@include max(w);
		}
	}

}
.entry-tags{
	padding: 20px 40px 20px 60px; border-top: 1px dashed $BDC;font-size: 1.3rem;position: relative;
	.fas{
		position: absolute;left:40px;top:50%;transform: translateY(-50%);
	}
}
@include for-sp(){
	.entry-tags{
		padding: 15px 25px;font-size: 1.2rem;
		.fas{
			display: none;
		}
	}
}
.entry-tag-label{
	display: inline-block;margin-right: 10px;
}

.entry-body-pager{
	display: flex;justify-content: space-between;margin: 40px 20px;align-items: stretch;font-size: 1.4rem;
	li{
		text-align: center;border-left: 1px solid $BDC;width: calc(100% - 440px);
		display: flex;flex-direction: column; justify-content: center;align-items: center;
		&:first-child{border: none;}
	}
	.entry-body-prev{
		width: 240px;text-align: left;padding-right: 20px;align-items: flex-start;
	}
	.entry-body-next{
		width: 240px;text-align: left;padding-left: 20px;align-items: flex-end;
	}
	.paging-label{
		display: block;font-size: 1.2rem;font-weight: 500;
	}
	.paging-entry{
		display: block;line-height: 1.4;
	}
}
@include for-sp(){
	.entry-body-pager{
		width: 100%;margin: 20px 0;flex-wrap: wrap;
		li{
			order:1;width: 100%;border: none;
		}
		.entry-body-prev{
			order:2;width: 50%;font-size: 1.2rem;margin-top: 10px;border-right: 1px solid $BDC !important;border-top: 1px solid $BDC !important;
			padding-top: 10px;
		}
		.entry-body-next{
			order:3;width: 50%;font-size: 1.2rem;margin-top: 10px;border-top: 1px solid $BDC !important;
			padding-top: 10px;
		}
		.paging-entry{
			height: 3.36rem;overflow: hidden;
		}
	}	
}

//評価
.value-label{
	display: inline-block;color: $BLACK;font-weight: 500;
}
@include for-sp(){
	
}

.value-star{
	display: inline-block;color: $KEYCOLOR;font-size: 2.5rem;margin-right: 10px;
}
@include for-sp(){
	.value-star{display: block;line-height: 1;margin-bottom: 5px;}
}
//目次
#toc_container{
	display: flex;margin-top: 60px;
	.toc_title{
		font-size: 2.1rem;font-weight: 500;
		width: 50px;height: 100px;background: $KEYCOLOR;color: #fff;
		display: flex;justify-content: center;align-items: center;
		writing-mode: vertical-lr;letter-spacing: 0.2em;
		position: relative;
		&:before,&:after{
			content:"";width: 0;height: 0;border-style: solid;
			position: absolute;left:0;
		}
		&:before{
			border-width: 16px 16px 0 0;
			border-color: #fff transparent transparent transparent;
			top:0;
		}
		&:after{
			border-width: 16px 0 0 16px;
			border-color: transparent transparent transparent #fff;
			bottom:0;
		}
	}
	.toc_list{
		border-left: 1px solid $KEYCOLOR;width: calc(100% - 50px);padding-left: 30px;
		>li{
			border-bottom: 1px dotted $BDC;padding: 10px;line-height: 1.5;
			>a{color: $BLACK;
				&:hover{
					color: $KEYCOLOR;
				}
			}
		}
		ul{
			margin-left: 2em;font-size: 1.4rem;
			li{margin-top: 0;line-height: 2;}
		}
	}
}
@include for-sp(){
	#toc_container{
		margin-top: 40px;
		.toc_title{
			font-size: 1.4rem;width: 30px;height: 60px;
			&:before{
				border-width: 8px 8px 0 0;
			}
			&:after{
				border-width: 8px 0 0 8px;
			}
		}
		.toc_list{
			width: calc(100% - 30px);padding-left: 20px;
		}
	}
}


.invitation{
	display: inline-block;padding: 10px;
	border: 1px solid $BLACK;color: $BLACK;line-height: 1;
	&-label{
		display: inline-block;margin-right: 10px;font-size: 1.7rem;font-weight: 500;
	}
	&-code{
		display: inline-block;font-weight: 700;letter-spacing: 0.2em;font-size: 2.1rem;background: $BGC_GREEN;padding: 5px 10px;
	}
	
}

//ページネーション
.paginate{padding-top: 64px;width: 960px;margin: 0 auto;	
	.paginate-list{
		display: flex;justify-content: center;position: relative;
		li{
			width: 36px;padding-left: 0;margin: 0 10px;
			&:before{display: none;}
		}
		.number{
			display: block;font-size: 1.6rem;text-align: center;line-height: 36px;height: 36px;letter-spacing: 0;
			color: $FONT_COLOR;border: 1px solid $BLACK;
		}
		a.number{transition:background 0.3s $ease5;}
		a.number:hover{
			background: $BGC_GRAY;text-decoration: none;
		}
		.disabled{color: $BDC;
			.number{color: $BDC;}
		}
		.current{
			.number{background: $BGC_GRAY;pointer-events: none;}
		}
		.list{width: auto;}
		.prev,.next{
			position: absolute;top:0;
			.number{font-size: 2rem;border: none;}
		}
		.prev{left:0;}
		.next{right:0;}
	}
}



