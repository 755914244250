//form
input,buttontextarea,select{font-family:inherit;}
input[type="button"],input[type="submit"]{
	font-family:inherit;appearance: none; margin: 0;
}
input[type="text"],input[type="number"],input[type="tel"],input[type="password"],input[type="email"],textarea{
	appearance: none; width:100%;	border: 1px solid $BDC;	padding:3px 5px; border-radius:0;	
	background: transparent;
}
input[type="radio"],input[type="checkbox"]{margin-right: 5px;}
.mac{
	input[type="radio"],input[type="checkbox"]{vertical-align:0.2em;}
}
.select-wrap{
	position: relative; display: inline-block;max-width: 100%;
	&:after{content:"›";color: $BLACK;position: absolute;right:8px;top:calc(50% - 0.5em);line-height: 1;
		transform: rotate(90deg);z-index: 1;font-size: 1.4rem;
	}
	&:before{
		content:"";width: 1px;height: 100%;position: absolute;right:25px;top:0;background: $BDC;
	}
}
select{
	margin: 0;max-width: 100%;
	border: 1px solid $BDC;appearance: none;border-radius: 0;
	padding: 3px 33px 3px 8px;background: transparent;position: relative;z-index: 2;
}
select::-ms-expand { display: none;}

.radio {
  display: inline-block; cursor: pointer;position: relative;line-height: 1;padding: 8px 5px 8px 27px;
  margin-right: 30px;margin-top: 0 !important;font-size: 1.7rem;
  input {
    display: none;
    &:checked + .outer .inner {
      opacity: 1;transform: scale(1);
    }
    &:checked + .outer { border: 2px solid $RED; }
    &:focus + .outer .inner {
      opacity: 1; background-color: $RED;
    }
    &:disabled + .outer{
			background: #CCC;
    }
  }
  
  // Outer ring of custom radio
  .outer {
		width: 20px;height: 20px;position: absolute;top:6px;left:0;margin: 0;
    display: block;border: 2px solid $BLACK;  border-radius: 50%;
    transition:border 0.2s linear;
  }
  
  // Inner ring of custom radio
  .inner {
		width: 10px;height: 10px;display: block;
		border-radius: 50%; background-color: $RED;opacity: 0;
		position: absolute;left:3px;top:3px;
		overflow: hidden;text-indent: -9999px;
		transition:opacity 0.1s linear,transform 0.3s $ease9;
		transform: scale(0.7);
  }
  
  &:hover{
	 .outer{border-color: rgba($RED,0.5);} 
  }
}

.win .radio{
	padding: 13px 5px 13px 27px;
	.outer{
		top:11px;
	}
}


:placeholder-shown {color: #bbb; }
::-webkit-input-placeholder {color:#bbb; }
::-moz-placeholder {color: #bbb; opacity: 1; }
:-ms-input-placeholder {color:#bbb; }

#contact-submit{
	display: block;font-size: 2.1rem;color: #fff;width: 425px;margin: 0 auto;padding: 25px 0;line-height: 1;
	background: #000;font-weight: bold;
	&:hover{
	}
}
