//layout

// ラッパー
#wrapper{
	width: 100%;min-width: 1100px;overflow: hidden;
}

// ヘッダー
header{
	width: 100%;background: #fff;height: 100px;
	display: flex;align-items: center;padding: 0 40px;justify-content: space-between;
}
.logo{
	width: 300px;
	img{@include max(w);}
}
// グローバルナビ
.navi-wrap{

}
#menu{
	width: calc(100% - 300px - 110px);
	
}
#menu-btn{
	display: none;
}
.navi-wrap{
	display: flex;flex-direction: column;//padding-right: 40px;
}
.gnavi{
	order:2;
}
.hnavi{
	order:1;
}
.gnavi-list{
	display: flex;justify-content: flex-end;
	margin-top: 15px;font-weight: 500;white-space: nowrap;
	li{
		margin-left: 3em;
	}
	a{
		color: $BLACK;display: block;padding: 10px 0;
		position: relative;
		&:before{
			content:"";width: 100%;height: 2px;background: $KEYCOLOR;
			position: absolute;bottom:0;left:0;opacity: 0;
			transition:all 0.2s $ease5;
		}
	}
	.active a,a:hover{
		text-decoration: none;
		&:before{
			opacity: 1;bottom:5px;
		}
	}
}
.hnavi-list{
	order:1;
	display: flex;justify-content: flex-end;
	font-size: 1.2rem;
	li{margin-left: 3em;}
	a{
		color: $FONT_COLOR;
		&:hover{
			color:$KEYCOLOR;text-decoration: none;
		}
	}
}

.head-share{
	width: 110px;display: flex;justify-content: space-between;align-items: center;
}

@media (max-width:1500px){
	header{
		height: 80px;
	}
	.logo{
		width: 240px;
	}
	#menu{
		width: calc(100% - 240px - 90px);
	}
	.gnavi-list{
		margin-top: 10px;
		li{
			margin-left: 2em;
		}
		a{
			padding: 5px 0 10px;
		}
	}
	.head-share{
		width: 90px;
	}
}

@media (max-width:1280px){
	header{
		padding: 0 25px;
	}
	.gnavi-list{
		font-size: 1.5rem;
	}
}

@include for-sp(){
	#wrapper{
		min-width: 0;
	}
	header{
		height: 60px;padding: 0 6%;
	}
	.logo{
		width: 170px;
	}
	#menu{
		width: calc(100% - 170px);
	}
	#menu-btn{
		display: block;width: 50px;height: 50px;
		position: fixed;right:5px;top:5px;cursor: pointer;z-index: 999;overflow: hidden;
		.menu-line{display: block;width: 24px;height: 2px;border-radius: 1px;
			background: $KEYCOLOR;position: absolute;left:calc(50% - 12px);
			&:nth-child(1){top:17px;transition:background 0.2s linear, top 0.1s $ease2 0.2s,transform 0.2s $ease2 0.05s;}
			&:nth-child(2){top:24px;transition: transform 0.1s $ease2 0s;}
			&:nth-child(3){top:31px;transition: background 0.2s linear,top 0.1s $ease2 0.2s,transform 0.2s $ease2 0.05s;}
		}
	}
	.navi-wrap{
		display: block;visibility: hidden;opacity: 0;transition: all 0.5s $ease5;
		width: 100%;height: 100%;padding: 60px 20px;background: rgba($KEYCOLOR,0.9);backdrop-filter:blur(3px);
		overflow: auto;	-webkit-overflow-scrolling:touch;
		position: fixed;z-index: 998;top:0;left:0;
	}
	#menu.open{
		#menu-btn{
			.menu-line{
				background: #fff;
				&:nth-child(1){top:29px;transform: rotate(-135deg);transition:background 0.2s linear, top 0.1s $ease2 0.05s,transform 0.2s $ease2 0.1s;}
				&:nth-child(2){transform: scaleX(0);}
				&:nth-child(3){top:29px;transform: rotate(135deg);transition:background 0.2s linear, top 0.1s $ease2 0.05s,transform 0.2s $ease2 0.1s;}
			}
		}
		.navi-wrap{visibility: visible;opacity: 1;}
	}
	.gnavi{
		order:1;
	}
	.hnavi{
		order:2;
	}
	.gnavi-list{
		flex-direction: column; margin-top: 0;text-align: center;font-size: 1.8rem;
		li{
			margin-left: 0;margin: 0 auto;width: auto;
		}
		a{
			padding: 20px 0;color: #fff;line-height: 1;
			&:before{
				bottom:18px !important;background: #fff;height: 1px;
			}
		}
	}
	.hnavi-list{
		flex-direction: column;margin-top: 20px;border-top: 1px solid rgba(255,255,255,0.5);padding-top: 20px;
		font-size: 1.4rem;text-align: center;
		li{margin-left: 0;}
		a{
			display: block;color: #fff;padding: 10px 0;
			&:hover{
				color:#fff;
			}
		}
	}
}

main{
	display: flex;justify-content: space-between;padding-top: 60px;padding-bottom: 100px;
	width: 1100px;margin: 0 auto;
}

article{
	width: 728px;
}

// サイドバー 
.side{
	width: 300px;
}
@media (max-width:1120px){
	main{
		width: 1040px;
	}
}

@include for-sp(){
	main{
		display: block;width: 100%;padding: 20px 10px;
	}
	article{
		width: 100%;
	}
	.side{
		width: 100%;margin-top: 40px;
	}
}

.profile{
	background: #fff;margin-top: 30px;
}
.profile-tit{
	background: $KEYCOLOR;padding: 8px 0;text-align: center;
	font-size: 1.6rem;font-weight: 500;color: #fff;
}
.profile-box{
	padding: 20px;text-align: center;
}
.profile-image{
	display: block;width: 100px;height: 100px;overflow: hidden;border-radius: 50%;
	margin: 0 auto;
	img{
		width:100%;height: 100%;object-fit:cover;
	}
}
.profile-name{
	font-size: 1.4rem;font-weight: 500;color: $BLACK;
}
.profile-twitter{
	font-size: 1.1rem;margin-top: 8px;
}
.profile-text{
	font-size: 1.2rem;text-align: left;margin-top: 8px;
}
.side-banner-list{
	.banner{
		margin-top: 20px;
		a{
			width: 100%;height: 80px;display: flex;align-items: center;justify-content: center;
			color: $KEYCOLOR;border: 1px solid $KEYCOLOR;background: #FFF;font-weight: 700;
			&:hover{
				background: $KEYCOLOR;color: #FFF;text-decoration: none;
			}
		}
	}
}
@include for-sp(){
	.side-banner-list{
		display: grid;width: 100%;margin: 0 auto;
		grid-template-columns:1fr 1fr;
		grid-gap: 10px;
		.banner{
			text-align: center;margin-top: 0;
			a{
				height: 60px;
				
			}
		}
	}
}

.side-block{
	margin-top: 40px;
}
@include for-sp(){
	.side-block{
		margin-top: 20px;
	}
}
.side-title{
	color: $BLACK;font-size: 1.8rem;font-weight: 500;
}
@include for-sp(){
	.side-title{
		font-size: 1.6rem;text-align: center;
	}
}

//関連リンク
.related-entry{
	width: 300px;margin: 12px auto;
	a{
		display: flex;align-items: center;
		&:hover{
			text-decoration: none;color: $KEYCOLOR;
		}
	}
}
.related-entry-thumb{
	width: 80px;height: 80px;margin-right: 10px;
	img{width: 100%;height: 100%;object-fit:cover;}
}
.related-entry-tit{
	width: calc(100% - 90px);font-size: 1.4rem;font-weight: 500;line-height: 1.4;
	color: $BLACK;
}
.related-noentry{
	margin-top: 15px;font-size: 1.3rem;
}

@include for-sp(){
	.related-entry{
		margin-top: 10px;
	}
	.related-entry-thumb{
		width: 70px;height: 70px;
	}
	.related-entry-tit{
		width: calc(100% - 80px);font-size: 1.3rem;
	}
}

// フッター
footer{
	background: #343636;padding: 35px 0 15px;
}
.foot-inner{
	width: 1100px;margin: 0 auto;
	display: flex;justify-content: space-between;align-items: center;
}

@include for-sp(){
	.foot-inner{
		width: 100%;padding: 0 8%;
		display: block;
	}
}

.foot-logo{
	width: 126px;
	img{@include max(w);}
}

@include for-sp(){
	.foot-logo{
		width: 100px;margin: 0 auto;
	}
}

.foot-right{
	width: calc(100% - 126px);
	display: flex;flex-direction: column;align-items: flex-end;
}
@include for-sp(){
	.foot-right{
		width: 320px;display: block;margin: 0 auto;
	}
}
.fnavi-list{
	display: flex;justify-content: flex-end;font-size: 1.3rem;
	li{
		margin-left: 2em;
		&:first-child{margin-top: 0;}
	}
	a{
		color: #AFAFAF;
		&:hover{
			color: $KEYCOLOR;text-decoration: none;
		}
	}
}
@include for-sp(){
	.fnavi-list{
		font-size: 1.2rem;margin-top: 25px;
		display: grid;
		grid-template-columns:1fr 1fr;
		grid-gap: 10px;
		li{
			margin-left: 0;padding-left: 1.5em;
		}
		a{
			color: #fff;
		}
	}
}
.copyright{
	display: block;font-size: 1.1rem;color: #AFAFAF;margin-top: 30px;
}
@include for-sp(){
	.copyright{
		font-size: 1rem;text-align: center;
	}
}
.copymark{
	font-family: Verdana,"Droid Sans";
}

.pagetop{display: none;}